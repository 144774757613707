<nav id="top">
    <div class="logo">
        <a routerLink="/">
        <img width="120px"  src="./../../../assets/icons/matsis-logo-black.svg" alt="">
        </a>
    </div>
    <div class="actionBar">
        <nz-space [nzSize]="16">

            <!-- todo Notifications -->
            <div class="mr-1"><a class="car" routerLink="ariza-bildir"><fa-icon [icon]="faCarCrash"></fa-icon></a></div>
            
            <nz-badge *nzSpaceItem nzDot><i nz-icon nzType="notification"></i></nz-badge>

            <!-- todo Profile -->
            <a class="topMenuDrop" *nzSpaceItem nz-dropdown [nzDropdownMenu]="menu">
                <nz-avatar nzIcon="user" nzSrc="//zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"></nz-avatar>
                <i nz-icon nzType="down"></i>
            </a>

            <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu nzSelectable>
                    <li nz-menu-item>
                        <a routerLink="/settings/profil">
                            <i nz-icon nzType="user" nzTheme="outline"></i> Profil</a>
                    </li>

                    <li nz-menu-item>
                        <a (click)="signOut()"><i nz-icon nzType="export" nzTheme="outline"></i>Çıkış</a>
                    </li>
                </ul>
            </nz-dropdown-menu>
        </nz-space>
    </div>
</nav>
<router-outlet></router-outlet>