// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  production: false,
        //  apiURL: 'https://api.projeadi.com/api',
        apiURL: 'https://boluistakip.ndepo.com/api',
        // apiURL: 'https://localhost:5001/api',
         firebase : {
          apiKey: "AIzaSyBGvWkkhckdjuStYljtM6UeyKe_TPoi7Vw",
          authDomain: "aydemir-altyapi.firebaseapp.com",
          projectId: "aydemir-altyapi",
          storageBucket: "aydemir-altyapi.appspot.com",
          messagingSenderId: "1024051983668",
          appId: "1:1024051983668:web:035e7be5b548d28de8e161",
          measurementId: "G-YTV9JDXJM1"
        }


};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
